import React, { useState, useEffect } from "react"
import styled from "styled-components"
import axios from "axios"
import { Form, Dropdown } from "react-bootstrap"
import {
  FormCheckCol,
  CheckSwiftCode,
  LabelForm,
  CheckAndFindButton,
} from "./SwiftCodeStyle"
import SearchImg from "../../images/icon-swift-search.svg"
import listCountriesJson from "../data/countries.json"

function DropdownSearch({ propsData, propsToParent, triggerToParent }) {
  const {
    Checker1d,
    Checker2a,
    Checker2b,
    Checker2c,
    Checker2d,
    Checker2e,
    Checker2f,
    Checker2g,
  } = propsData

  const [listBank, setListBank] = useState()
  const [listCity, setListCity] = useState()
  const [firstTrigger, setFirstTrigger] = useState(false)
  const [secondTrigger, setSecondTrigger] = useState(false)
  const [thirdTrigger, setThirdTrigger] = useState(false)
  const [firstLoading, setFirstLoading] = useState(false)
  const [secondLoading, setSecondLoading] = useState(false)
  const [selectOneCountry, setSelectOneCountry] = useState(Checker2d)
  const [selectOneBank, setSelectOneBank] = useState(Checker2d)
  const [selectOneCity, setSelectOneCity] = useState(Checker2d)
  const [finalData, setFinalData] = useState()
  const [finalTrigger, setFinalTrigger] = useState(false)
  const [dataCountries, setDataCountries] = useState(listCountriesJson)
  const [dataBank, setDataBank] = useState([])
  const [dataCity, setDataCity] = useState([])

  const SortedArrayofObject = array => {
    return array.sort(function (a, b) {
      return a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    })
  }

  const HandleCountry = event => {
    setSelectOneCountry(event)
    setSelectOneBank(Checker2d)
    setSelectOneCity(Checker2d)
    setFirstTrigger(true)
    setSecondTrigger(false)
    setThirdTrigger(false)
  }

  const HandleBank = event => {
    setSelectOneBank(event)
    setSelectOneCity(Checker2d)
    setSecondTrigger(true)
    setThirdTrigger(false)
  }

  const HandleCity = event => {
    axios
      .get(`https://strapi.transfez.com/swift-codes?swift_code_eq=${event}`)
      .then(res => {
        setSelectOneCity(res.data[0].city)
        setThirdTrigger(true)
      })
  }

  async function FetchBank() {
    setFirstLoading(true)
    await axios
      .get(
        `https://strapi.transfez.com/swift-codes?country_eq=${selectOneCountry}&_limit=-1`
      )
      .then(res => {
        let pp = res.data.filter(
          (ele, ind) =>
            ind === res.data.findIndex(elem => elem.bank === ele.bank)
        )
        setListBank(pp)
        setDataBank(pp)
        setFirstLoading(false)
      })
  }

  async function FetchCity() {
    setSecondLoading(true)
    await axios
      .get(
        `https://strapi.transfez.com/swift-codes?bank_eq=${selectOneBank}&_limit=-1`
      )
      .then(res => {
        setListCity(res.data)
        setDataCity(res.data)
        setSecondLoading(false)
      })
  }

  async function FetchForResult() {
    await axios
      .get(
        `https://strapi.transfez.com/swift-codes?bank_eq=${selectOneBank}&city_eq=${selectOneCity}`
      )
      .then(res => {
        setFinalData(res.data[0])
      })
  }

  function onSubmit(e) {
    e.preventDefault()
    FetchForResult()
    setFinalTrigger(true)
  }

  useEffect(() => {
    FetchBank()
  }, [selectOneCountry])

  useEffect(() => {
    FetchCity()
  }, [selectOneBank])

  useEffect(() => {
    propsToParent(finalData)
    triggerToParent(finalTrigger)
  }, [finalData])

  const handleSearchCountry = event => {
    var searchQuery = event.target.value.toLowerCase()
    var displayedContacts = listCountriesJson.filter(function (el) {
      var searchValue = el.name.toLowerCase()

      return searchValue.indexOf(searchQuery) !== -1
    })
    setDataCountries(displayedContacts)
  }

  const handleSearchBank = event => {
    var searchQuery = event.target.value.toLowerCase()
    var displayedContacts = listBank.filter(function (el) {
      var searchValue = el.bank.toLowerCase()

      return searchValue.indexOf(searchQuery) !== -1
    })
    setDataBank(displayedContacts)
  }

  const handleSearchCity = event => {
    var searchQuery = event.target.value.toLowerCase()
    var displayedContacts = listCity.filter(function (el) {
      var searchValue = el.city.toLowerCase()

      return searchValue.indexOf(searchQuery) !== -1
    })
    setDataCity(displayedContacts)
  }

  return (
    <>
      <FormCheckCol md={6} mrestop="0" className="kanan">
        <CheckSwiftCode>
          <h3>{Checker2a}</h3>
          <p>{Checker2b}</p>
          <Form onSubmit={onSubmit}>
            <LabelForm>{Checker2c}</LabelForm>
            <br />
            <Dropdown>
              <CustomDropdownToggle>{selectOneCountry}</CustomDropdownToggle>
              <CustomDropdownMenu>
                <SearchBox type="text" onChange={handleSearchCountry} />
                {dataCountries.map((country, idx) => (
                  <CustomDropdownItem
                    key={idx}
                    eventKey={country.name}
                    onSelect={HandleCountry}
                  >
                    {country.name}
                  </CustomDropdownItem>
                ))}
              </CustomDropdownMenu>
            </Dropdown>

            {/* PILIH BANK */}
            {firstLoading ? (
              <small>Your application is being processed, please wait…</small>
            ) : (
              <>
                {firstTrigger ? (
                  <>
                    <LabelForm>{Checker2e}</LabelForm>
                    <br />
                    <Dropdown>
                      <CustomDropdownToggle>
                        {selectOneBank}
                      </CustomDropdownToggle>
                      <CustomDropdownMenu>
                        <SearchBox type="text" onChange={handleSearchBank} />
                        {dataBank.map((bank, idx) => (
                          <CustomDropdownItem
                            key={idx}
                            eventKey={bank.bank}
                            onSelect={HandleBank}
                          >
                            {bank.bank}
                          </CustomDropdownItem>
                        ))}
                      </CustomDropdownMenu>
                    </Dropdown>
                  </>
                ) : null}
              </>
            )}

            {/* PILIH Kota */}
            {secondLoading ? (
              <small>Your application is being processed, please wait…</small>
            ) : (
              <>
                {secondTrigger ? (
                  <>
                    <LabelForm>{Checker2f}</LabelForm>
                    <br />
                    <Dropdown>
                      <CustomDropdownToggle>
                        {selectOneCity}
                      </CustomDropdownToggle>
                      <CustomDropdownMenu>
                        <SearchBox type="text" onChange={handleSearchCity} />
                        {dataCity.map((city, idx) => (
                          <CustomDropdownItem
                            key={idx}
                            eventKey={city.swift_code}
                            onSelect={HandleCity}
                          >
                            {city.city}
                          </CustomDropdownItem>
                        ))}
                      </CustomDropdownMenu>
                    </Dropdown>
                  </>
                ) : null}
              </>
            )}
            <div className="text-center">
              {thirdTrigger ? (
                <CheckAndFindButton type="submit">
                  {Checker2g}
                </CheckAndFindButton>
              ) : (
                <CheckAndFindButton type="submit" disabled>
                  {Checker2g}
                </CheckAndFindButton>
              )}
            </div>
          </Form>
        </CheckSwiftCode>
      </FormCheckCol>
    </>
  )
}

export default DropdownSearch

const CustomDropdownToggle = styled(Dropdown.Toggle)`
  /* max-width: 400px; */
  width: 100% !important;
  background-color: #fff !important;
  color: #B3B3B3 !important;
  font-family: "Typefez-reg";
  margin-bottom: 1.5rem;
  border-radius: 10px !important;
  height: 48px !important;
  text-align: left;
  :after {
    margin-top: 8px;
    font-size: 25px;
    float: right;
    text-align: right !important;
    color: #DB64A0 !important;
  }
  :hover {
    background-color: #fff !important;
    color: #B3B3B3 !important;
  }
`

const CustomDropdownItem = styled(Dropdown.Item)`
  width: 400px;
  font-family: "Typefez-reg";
  :hover{
    background-color: #DB64A0;
    color: white;
  }
`

const CustomDropdownMenu = styled(Dropdown.Menu)`
  height: auto !important;
  max-height: 250px;
  overflow: auto !important;
`

const smallLoading = styled.small`
  font-family: "GothamLight";
  color: #48a9b5;
`

const SearchBox = styled.input`
  min-width: 380px;
  padding: 5px 10px;
  margin: 0px 15px 5px 15px;
  font-family: "Typefez-reg";
  outline: none;
  border: solid 1px #d6d6d6;
  border-radius: 10px;
`
