import React, { useState, useEffect } from "react"
import "../layout.css"
import { useLocation } from "@reach/router"
import axios from "axios"
import HeaderSwiftCodeDetails from "../HeaderComponents/HeaderSwiftCodeDetails.js"
import Download from "../HomeComponents/Download"
import AboutSwiftCodes from "../SwiftCodes/AboutSwiftCodes"
import Footer from "../FooterComponents/Footer"
import { MainPageJson, PathJson, SwiftCodesJson } from "../location"
import Seo from "../SEOComponents/Seo.js"
import { WhereAndWhenSwift } from "./WhereAndWhen.js"
import { NavigationBar } from "../NavigationBar/parent.js"

const SwiftCodeDetails = props => {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const SwiftCodesContent = SwiftCodesJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")

  const [loading, setLoading] = useState(false)
  const [detailData, setDetailData] = useState({})

  // const FetchData = async () => {
  //   await axios
  //     .get(
  //       `https://strapi.transfez.com/swift-codes?swift_code_eq=${props.code}`
  //     )
  //     .then(res => {
  //       // setDefaultCountry(res.data[0].country)
  //       setDetailData({
  //         bank: res.data[0].bank,
  //         country: res.data[0].country,
  //         swift_code: res.data[0].swift_code,
  //         branch: res.data[0].branch,
  //         city: res.data[0].city,
  //       })
  //       setLoading(true)
  //     })
  //     .catch(err => {
  //       navigate("/swift-codes/")
  //     })
  // }

  const FetchData = async () => {
    try {
      setLoading(true)
      const { data } = await axios.get(
        `https://strapi.transfez.com/swift-codes?swift_code_eq=${props.code}`
      )
      setDetailData(data[0])
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    FetchData()
  }, [props.code])

  // useEffect(() => {
  //   FetchRelated()
  // }, [detailData])

  return (
    <>
      {loading ? <div></div> : (
        <div className="maxwidth">
          <Seo
            title="swift-code-details"
            swiftcodes={detailData}
            swiftCanonical={props.code}
          />
          <NavigationBar />
          <HeaderSwiftCodeDetails
            props={MainPageContent.Header}
            propsLink={PathContent}
            propsData={SwiftCodesContent}
            propsSwift={detailData}
          />
          <AboutSwiftCodes
            propsData={SwiftCodesContent}
            propsSwift={detailData.bank}
          />
          <WhereAndWhenSwift propsData={SwiftCodesContent} />
          <Download props={MainPageContent.Download} />
          <Footer props={MainPageContent.Footer} propsLink={PathContent} />
        </div>
      )}
    </>
  )
}

export default SwiftCodeDetails
