import React from "react"
import { Container, Row } from "react-bootstrap"
import bgCloud from "../../images/bg-cloud.svg"
import globe from "../../images/illustration-swift-world.svg"
import {
  WhenWeNeedSection,
  WhenWeNeedCol,
  WhenWeNeedImgCol,
  BrWhenWeNeed,
} from "./SwiftCodeStyle"

function WhenWeNeed({ propsData, propsSwift }) {
  const { Title9, Desc9 } = propsData.Body
  const {
    Title8,
    Title8a,
    Desc8,
    Desc8a,
    Desc8b,
    Desc8c,
  } = propsData.Details.Body
  return (
    <>
      <WhenWeNeedSection bg={bgCloud}>
        <Container>
          <Row>
            {propsSwift ? (
              <>
                <WhenWeNeedCol md={6}>
                  <h2>
                    {Title8}
                    {propsSwift}
                    {Title8a}
                  </h2>
                  <p>
                    {Desc8}
                    {propsSwift}
                    {Desc8a}
                    {propsSwift}
                    {Desc8b}
                    {propsSwift}
                    {Desc8c}
                    {propsSwift}
                  </p>
                </WhenWeNeedCol>
                <WhenWeNeedImgCol md={6}>
                  <img alt="globe" src={globe} style={{ maxWidth: "390px" }} />
                </WhenWeNeedImgCol>
              </>
            ) : (
              <>
                <WhenWeNeedCol md={6}>
                  <h2>{Title9}</h2>
                  <p>{Desc9}</p>
                </WhenWeNeedCol>
                <WhenWeNeedImgCol md={6}>
                  <img alt="globe" src={globe} style={{ maxWidth: "390px" }} />
                </WhenWeNeedImgCol>
              </>
            )}
          </Row>
        </Container>
      </WhenWeNeedSection>
    </>
  )
}

export default WhenWeNeed
