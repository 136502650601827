import React from "react"
import styled from "styled-components"
import smallGlobeImg from "../../images/swift-codes-globe-small.png"
import { Container, Col, Row } from "react-bootstrap"
import { Link, graphql, useStaticQuery } from "gatsby"
import { useLocation } from "@reach/router"

function PopularSwiftCodes() {
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")

  const data = useStaticQuery(graphql`
    {
      first: allStrapiFirstTopSwifts {
        nodes {
          country_en
          country_id
          code
          bank
          id
        }
      }
      second: allStrapiSecondTopSwifts {
        nodes {
          country_en
          country_id
          code
          bank
          id
        }
      }
    }
  `)

  const firstEnCountry = data.first.nodes[0].country_en
  const firstIdCountry = data.first.nodes[0].country_id
  const secondEnCountry = data.second.nodes[0].country_en
  const secondIdCountry = data.second.nodes[0].country_id
  const countryNameFirst = isEnglish ? firstEnCountry : firstIdCountry
  const countryNameSecond = isEnglish ? secondEnCountry : secondIdCountry

  return (
    <MainDiv>
      <PopularContainer>
        <Row>
          <Col md={6}>
            <PopularDiv>
              <TitleP>
                Popular Swift Code in{" "}
                <span style={{ color: "#82B4E1" }}>{countryNameFirst}</span>
              </TitleP>
              {data.first.nodes.map(swift => {
                const newCode = swift.code.toLowerCase()
                return (
                  <SwiftLink
                    to={
                      isEnglish
                        ? `/en/swift-codes/${newCode}`
                        : `/swift-codes/${newCode}`
                    }
                    key={swift.id}
                  >
                    SWIFT code {swift.bank}
                  </SwiftLink>
                )
              })}
            </PopularDiv>
          </Col>
          <Col md={6}>
            <PopularDiv>
              <TitleP>
                Popular Swift Code in{" "}
                <span style={{ color: "#82B4E1" }}>{countryNameSecond}</span>
              </TitleP>
              {data.second.nodes.map(swift => {
                const newCode = swift.code.toLowerCase()
                return (
                  <SwiftLink
                    to={
                      isEnglish
                        ? `/en/swift-codes/${newCode}`
                        : `/swift-codes/${newCode}`
                    }
                    key={swift.id}
                  >
                    SWIFT code {swift.bank}
                  </SwiftLink>
                )
              })}
              <SmallImg src={smallGlobeImg} alt="globe" />
            </PopularDiv>
          </Col>
        </Row>
      </PopularContainer>
    </MainDiv>
  )
}

export default PopularSwiftCodes

const MainDiv = styled.div`
  position: relative;
  z-index: 3;
  background-color: transparent;
`

const PopularContainer = styled(Container)`
  position: relative;
  z-index: 5;
  background: white;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  padding: 50px;
  margin-bottom: 100px;
`

const TitleP = styled.h4`
  font-family: "Typefez-black";
  font-size: 22px;
  color: #102c57;
`

const PopularDiv = styled.div`
  padding: 1rem;
`
const SwiftLink = styled(Link)`
  color: #da649f;
  font-family: "Typefez-bold";
  text-decoration: underline;
  display: flex;
  flex-direction: column;
  :hover {
    color: #da649f;
    filter: brightness(80%);
  }
`

const SmallImg = styled.img`
  position: absolute;
  right: -150px;
  top: -10px;
  @media (max-width: 768px) {
    display: none;
  }
`
