import React, { useState, useEffect } from "react"
import { useLocation, navigate } from "@reach/router"
import axios from "axios"
import { Form } from "react-bootstrap"
import DropdownSearch from "./DropdownSearch"
import {
  CheckAndFindSection,
  FormCheckCol,
  CheckSwiftCode,
  LabelForm,
  CheckAndFindContainer,
  CheckAndFindInput,
  CheckAndFindButton,
} from "./SwiftCodeStyle"
import "./style.css"
import CheckImg from "../../images/icon-swift-check.svg"

function CheckAndFind({ propsData, propsToParent, triggerToParent }) {
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const { Checker1a, Checker1b, Checker1c, Checker1d } = propsData
  const [swiftCode, setSwiftCode] = useState({
    code: "",
  })

  function onChange(e) {
    setSwiftCode({ code: e.target.value })
  }

  async function fetchData() {
    await axios
      .get(
        `https://strapi.transfez.com/swift-codes?swift_code_eq=${swiftCode.code}`
      )
      .then(res => {
        if (res.data.length === 0) {
          navigate(
            isEnglish
              ? `/en/bic-swift-code-checker/`
              : `/bic-swift-code-checker/`
          )
          localStorage.setItem("WrongCode", swiftCode.code)
        } else {
          const newLink = res.data[0].swift_code.toLowerCase()
          navigate(
            isEnglish ? `/en/swift-codes/${newLink}` : `/swift-codes/${newLink}`
          )
          console.log(res.data[0])
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  function onSubmit(e) {
    e.preventDefault()
    fetchData()
  }

  return (
    <>
      <CheckAndFindSection>
        <CheckAndFindContainer>
          <div className="row">
            <FormCheckCol md={6} mrestop="-175px">
              <CheckSwiftCode>
                {/* <img src={CheckImg} alt="Check SWIFT/BIC code online" /> */}
                <h3>{Checker1a}</h3>
                <p>{Checker1b}</p>
                <Form onSubmit={onSubmit}>
                  <LabelForm>{Checker1c}</LabelForm>
                  <br />
                  <CheckAndFindInput
                    type="text"
                    name="swiftcode"
                    className="form-control"
                    placeholder="AAAA-BB-CC-123"
                    onChange={onChange}
                  />
                  <div className="text-center">
                    <CheckAndFindButton type="submit">
                      {Checker1d}
                    </CheckAndFindButton>
                  </div>
                </Form>
              </CheckSwiftCode>
            </FormCheckCol>

            {/* KOLOM KANAN */}

            <DropdownSearch
              propsData={propsData}
              propsToParent={propsToParent}
              triggerToParent={triggerToParent}
            />
          </div>
        </CheckAndFindContainer>
      </CheckAndFindSection>
    </>
  )
}

export default CheckAndFind
