import React from "react"
import {Router as MyRouter} from '@reach/router'
import "../components/layout.css"

import SwiftCode from '../components/SwiftCodes/SwiftCodePages'
import SwiftCodeDetails from '../components/SwiftCodes/SwiftCodeDetails'

function SwiftCodes() {
  return (
    <>
      <MyRouter>  
        <SwiftCode path="/swift-codes/" />
        <SwiftCode path="/en/swift-codes/" />
        <SwiftCodeDetails path="/swift-codes/:code/" />
        <SwiftCodeDetails path="/en/swift-codes/:code/" />
      </MyRouter>
    </>
  )
}

export default SwiftCodes
