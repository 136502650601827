import React from "react"
import { Container, Col, Row } from "react-bootstrap"

import {
  AboutSection,
  WhatSwiftCode,
  FormatSwiftCode,
  ExplanationUl,
  ExplanationLi,
  ExplanationLiLast,
  ExampleSwiftCode,
  CodeCol,
} from "./SwiftCodeStyle"

function AboutSwiftCodes({ propsData, propsSwift }) {
  const {
    Title1,
    Desc1,
    Title2,
    Desc2,
    Title3,
    Desc3,
    Title4,
    Desc4,
    Title5,
    Desc5,
    Title6,
    Desc6,
    Title7,
    Title7a,
    Desc7,
    Title8,
    Title8a,
    Desc8,
  } = propsData.Body
  const json = propsData.Details.Body

  return (
    <>
      <AboutSection>
        <Container>
          {propsSwift ? (
            <>
              <WhatSwiftCode>
                <h2>
                  {json.Title1}
                  {propsSwift}
                  {json.Title1a}
                </h2>
                <p>
                  {json.Desc1}
                  {propsSwift}
                  {json.Desc1a}
                </p>
              </WhatSwiftCode>
              <FormatSwiftCode>
                <h2>
                  {json.Title2}
                  {propsSwift}
                  {json.Title2a}
                </h2>
                <Row>
                  <Col md={6}>
                    <p>{json.Desc2}</p>
                    <ExplanationUl>
                      <ExplanationLi>
                        <h3>{json.Title3}</h3>
                        <p>{json.Desc3}</p>
                      </ExplanationLi>
                      <ExplanationLi>
                        <h3>{json.Title4}</h3>
                        <p>{json.Desc4}</p>
                      </ExplanationLi>
                      <ExplanationLi>
                        <h3>{json.Title5}</h3>
                        <p>{json.Desc5}</p>
                      </ExplanationLi>
                      <ExplanationLiLast>
                        <h3>{json.Title6}</h3>
                        <p>{json.Desc6}</p>
                      </ExplanationLiLast>
                    </ExplanationUl>
                  </Col>
                  <Col md={6}>
                    <ExampleSwiftCode>
                      <h4>
                        {json.Title7}
                        {propsSwift}
                        {json.Title7a}
                      </h4>
                      <p>{json.Desc7}</p>
                      <Row>
                        <CodeCol className="col-6" lg={3}>
                          <p>AAAA</p>
                          <small>{Title4}</small>
                        </CodeCol>
                        <CodeCol className="col-6" lg={3}>
                          <p>BB</p>
                          <small>{Title5}</small>
                        </CodeCol>
                        <CodeCol className="col-6" lg={3}>
                          <p>CC</p>
                          <small>{Title6}</small>
                        </CodeCol>
                        <CodeCol className="col-6" lg={3}>
                          <p>123</p>
                          <small>{Title7}</small>
                        </CodeCol>
                      </Row>
                    </ExampleSwiftCode>
                  </Col>
                </Row>
              </FormatSwiftCode>
            </>
          ) : (
            <>
              <WhatSwiftCode>
                <h2>{Title1}</h2>
                <p>{Desc1}</p>
              </WhatSwiftCode>
              <WhatSwiftCode>
                <h2>{Title2}</h2>
                <p>{Desc2}</p>
              </WhatSwiftCode>
              <FormatSwiftCode>
                <h2>{Title3}</h2>
                <Row>
                  <Col md={6}>
                    <p>{Desc3}</p>
                    <ExplanationUl>
                      <ExplanationLi>
                        <h3>{Title4}</h3>
                        <p>{Desc4}</p>
                      </ExplanationLi>
                      <ExplanationLi>
                        <h3>{Title5}</h3>
                        <p>{Desc5}</p>
                      </ExplanationLi>
                      <ExplanationLi>
                        <h3>{Title6}</h3>
                        <p>{Desc6}</p>
                      </ExplanationLi>
                      <ExplanationLiLast>
                        <h3>
                          {Title7}
                          {Title7a}
                        </h3>
                        <p>{Desc7}</p>
                      </ExplanationLiLast>
                    </ExplanationUl>
                  </Col>
                  <Col md={6}>
                    <ExampleSwiftCode>
                      <h4>
                        {Title8}
                        <span style={{ color: "#82b4e1" }}>{Title8a}</span>
                      </h4>
                      <p>{Desc8}</p>
                      <Row>
                        <CodeCol className="col-6" lg={3}>
                          <p>AAAA</p>
                          <small>{Title4}</small>
                        </CodeCol>
                        <CodeCol className="col-6" lg={3}>
                          <p>BB</p>
                          <small>{Title5}</small>
                        </CodeCol>
                        <CodeCol className="col-6" lg={3}>
                          <p>CC</p>
                          <small>{Title6}</small>
                        </CodeCol>
                        <CodeCol className="col-6" lg={3}>
                          <p>123</p>
                          <small>{Title7}</small>
                        </CodeCol>
                      </Row>
                    </ExampleSwiftCode>
                  </Col>
                </Row>
              </FormatSwiftCode>
            </>
          )}
        </Container>
      </AboutSection>
    </>
  )
}

export default AboutSwiftCodes
