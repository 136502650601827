import React, { useState, useEffect } from "react"
import { useLocation, navigate } from "@reach/router"
// import "../components/layout.css"

import Seo from "../SEOComponents/Seo"
import HeaderSwiftCodes from "../HeaderComponents/HeaderSwiftCodes"
import Footer from "../FooterComponents/Footer"
import AboutSwiftCodes from "./AboutSwiftCodes"
import PopularSwiftCodes from "./PopularSwiftCodes"
import SearchResult from "./SearchResult"
import DownloadSection from "../DownloadAppSection/DownloadSection"
import MissionSection from "../Mission Section/MissionSection"
import Media from "../SendMoney/Media"
import WhenWeNeed from "./WhenWeNeed"
import WhereCanFind from "./WhereCanFind"
import CheckAndFind from "./CheckAndFind"

import { MainPageJson, PathJson, SwiftCodesJson } from "../location"
import Download from "../HomeComponents/Download"
import { WhereAndWhenSwift } from "./WhereAndWhen"
import { NavigationBar } from "../NavigationBar/parent"

const SwiftCodes = () => {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const SwiftCodesContent = SwiftCodesJson()
  const location = useLocation()
  const [propsResult, setPropsResult] = useState("")
  const [triggerResult, setTriggerResult] = useState(false)

  const handlePropsToParent = props => {
    setPropsResult(props)
  }

  const handleTriggerToParent = props => {
    setTriggerResult(props)
  }

  const isEnglish = location.pathname.includes("/en")
  const isNotSlash =
    location.pathname === "/swift-codes" ||
    location.pathname === "/en/swift-codes"

  useEffect(() => {
    if (isNotSlash) {
      if (isEnglish) {
        navigate("/en/swift-codes/")
      }
      if (!isEnglish) {
        navigate("/swift-codes/")
      }
    }
  }, [])

  return (
    <div className="maxwidth">
      <Seo title="swift-code" />
      <NavigationBar />
      <HeaderSwiftCodes
        props={MainPageContent.Header}
        propsLink={PathContent}
        propsData={SwiftCodesContent.Header}
        propsToParent={handlePropsToParent}
        triggerToParent={handleTriggerToParent}
      />
      <SearchResult
        propsData={SwiftCodesContent}
        propsResult={propsResult}
        propsTriggerResult={triggerResult}
      />
      <PopularSwiftCodes />
      <AboutSwiftCodes propsData={SwiftCodesContent} />
      <WhereAndWhenSwift propsData={SwiftCodesContent} />
      <Download props={MainPageContent.Download} />
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
      {/* <WhenWeNeed propsData={SwiftCodesContent} />
      <WhereCanFind propsData={SwiftCodesContent} /> */}
      {/* <DownloadSection props={MainPageContent.Download} /> */}
      {/* <Media /> */}
      {/* <MissionSection props={MainPageContent.Mission} /> */}
    </div>
  )
}

export default SwiftCodes
