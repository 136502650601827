import React, { useRef } from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { Container } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import {
  BoxSearchResultDiv,
  YellowTriangle,
  Triangle,
  LocationBox,
  SearchResultTextDiv,
  CodeBoxCopyDiv,
  ImgLocation,
} from "./SwiftCodeStyle"
import IconLocation from "../../images/icon-location.svg"

function SearchResult({ propsData, propsTriggerResult, propsResult }) {
  const { Title11, Title11a, Title11b } = propsData.Body
  const swiftCodeCopy = useRef(null)
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")

  function copyToClipboard(e) {
    swiftCodeCopy.current.select()
    document.execCommand("copy")
    e.target.focus()
    alert(`Copied the text: ${swiftCodeCopy.current.value}`)
  }

  return (
    <>
      <section className="Search py-5">
        {propsTriggerResult ? (
          <>
            <Container>
              <BoxSearchResultDiv>
                {/* <YellowTriangle>
                  <Triangle />
                </YellowTriangle> */}
                <h3>
                  {Title11}
                  {propsResult.bank}
                  {Title11a}
                  {propsResult.country} {propsResult.bank}
                </h3>
                <LocationBox>
                  <div className="d-flex align-items-center">
                    <ImgLocation src={IconLocation} alt="icon location" />
                    <SearchResultTextDiv>
                      <h4>{propsResult.bank}</h4>
                    </SearchResultTextDiv>
                  </div>
                  <div className="d-flex align-items-center">
                    <CodeBoxCopyDiv>
                      <input
                        ref={swiftCodeCopy}
                        type="text"
                        value={propsResult.swift_code}
                      />
                      <button
                        onClick={copyToClipboard}
                        className="btn"
                        style={{ fontFamily: "Typefez-reg" }}
                      >
                        Copy
                      </button>
                    </CodeBoxCopyDiv>
                    <Link
                      to={
                        isEnglish
                          ? `/en/swift-codes/${propsResult.swift_code}`
                          : `/swift-codes/${propsResult.swift_code}`
                      }
                      className="h5 mb-0"
                    >
                      {/* <span className="d-md-none">{Title11b}</span> */}
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        style={{ color: "white", marginLeft: "1rem" }}
                      />
                    </Link>
                  </div>
                </LocationBox>
              </BoxSearchResultDiv>
            </Container>
          </>
        ) : null}
      </section>
    </>
  )
}

export default SearchResult
