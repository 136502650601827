import React from "react"
import { useStateIfMounted } from "use-state-if-mounted"
import styled from "styled-components"
import { Row, Col, Container } from "react-bootstrap"
import { AffiliateDot, AffiliateBorder } from "./HeaderStyle"
import CheckAndFind from "../SwiftCodes/CheckAndFind"

import bgHeader from "../../images/bg-header-swift-code.png"
import { IdComponent } from "../Reusable/id-components"
import { BackgroundImg } from "../HomeComponents/HowToUse"
import bgGreyRotate from "../../images/logo-big-grey-rotate.svg"

function HeaderSwiftCodes({
  props,
  propsLink,
  propsData,
  propsToParent,
  triggerToParent,
}) {
  const { Title, Desc } = propsData
  return (
    <>
      <IdComponent title="search" />
      <SwiftCodesHeader>
        <SwiftCodesContainer>
          <Salutation>
            <h2>{Title}</h2>
            <SwiftCodesDesc>{Desc}</SwiftCodesDesc>
          </Salutation>
          <BackgroundImg
            src={bgGreyRotate}
            alt="logo"
            top="450px"
            left="-800px"
            // width="800px"
            rotate="180deg"
          />
        </SwiftCodesContainer>
        <CheckAndFind
          propsData={propsData}
          propsToParent={propsToParent}
          triggerToParent={triggerToParent}
        />
      </SwiftCodesHeader>
    </>
  )
}

export default HeaderSwiftCodes

const SwiftCodesHeader = styled.div`
  padding-top: 120px;
`

const SwiftCodesContainer = styled(Container)`
  margin-bottom: 220px !important;
  position: relative;
  z-index: 3;
  margin-top: 3rem;
  flex-grow: 1;
  @media (min-width: 1200px) {
    max-width: 960px;
  }
`

const Salutation = styled.div`
  > h2 {
    font-family: "Typefez-black";
    color: #82b4e1;
    text-align: center;
    font-size: 62px;
  }
`

const SwiftCodesDesc = styled.p`
  font-family: "Typefez-reg";
  color: #102c57;
  text-align: center;
  font-size: 16px;
  padding: 20px 10%;
`
